/* eslint-disable react/jsx-no-useless-fragment */
import React from "react";

interface ShowChildrenProps {
  when: unknown;
  children: React.ReactNode;
}

export function ShowChildren({ when, children }: ShowChildrenProps) {
  if (when) {
    return <>{children}</>;
  }

  return null;
}
