import React, { forwardRef } from "react";

import { extractAtomsFromProps } from "@dessert-box/core";
import clsx from "clsx";

import { getSprinkles } from "~styles/getSprinkles.css";

import { IconFontAwesome } from "~components/IconFontAwesome";

import { Box } from "../Box";
import { getTagStyles } from "./styles.css";

import type { VariantTagSizeEnum } from "./variants/variant_tag_size.css";
import type { ReactNode, Ref } from "react";
import type { VariantCardBackgroundEnum } from "~components/Card/styles.css";
import type { IconFontAwesomeProps } from "~components/IconFontAwesome";
import type { GetSprinklesArgs } from "~styles/getSprinkles.css";

export interface TagProps extends Omit<GetSprinklesArgs, "color"> {
  iconLeft?: IconFontAwesomeProps["icon"];
  iconRight?: IconFontAwesomeProps["icon"];
  isPrimary?: boolean;
  ornamentLeft?: ReactNode;
  ornamentRight?: ReactNode;
  tagSize?: VariantTagSizeEnum;
  title?: string;
  className?: string;
  cardBackgroundVariant?: VariantCardBackgroundEnum;
}

export const Tag = forwardRef(
  (
    {
      iconLeft,
      iconRight,
      tagSize: size,
      title,
      ornamentLeft,
      ornamentRight,
      className: userClassName,
      cardBackgroundVariant: cardBackground,
      ...rest
    }: TagProps,
    ref
  ) => {
    const { atomProps, otherProps } = extractAtomsFromProps(rest, getSprinkles);

    const tagClassNames = clsx(
      getTagStyles({ size, cardBackground }),
      getSprinkles(atomProps),
      userClassName
    );

    return (
      <Box
        className={tagClassNames}
        ref={ref as Ref<HTMLElement>}
        {...otherProps}
      >
        {iconLeft && <IconFontAwesome icon={iconLeft} />}
        {ornamentLeft}

        {title}

        {iconRight && <IconFontAwesome icon={iconRight} />}
        {ornamentRight}
      </Box>
    );
  }
);
