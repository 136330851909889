import React, { createContext, useMemo, useRef } from "react";

import { useDirectionAwareTransitions } from "~hooks/useDirectionAwareTransitions";

import { Box } from "~components/Box";

import { useSectionScrollProgress } from "./_useSectionScrollProgress";

import type { ReactNode } from "react";
import type { StepType } from "~components/Stepper/types";
import type { AnimationOrientation } from "~hooks/useDirectionAwareTransitions/types";
import type { varsClipPathInset } from "~styles/vars/varsClipPathInset.css";

export interface SequencedAnimationsContextContextProvided {
  current?: number;
  previous?: number;
  handleUserSetState?: (stringifiedIndex: `${number}`) => void;
  setMask?: (mask: keyof typeof varsClipPathInset) => void;
}

export interface SequencedAnimationsContextContextProviderProps {
  length: number;
  children: ReactNode | Array<ReactNode>;
  orientation?: AnimationOrientation;
  stepsType?: Array<StepType>;
}

export const SequencedAnimationsContext =
  createContext<SequencedAnimationsContextContextProvided>({});

export function SequencedAnimationsContextProvider({
  length,
  children,
  orientation = "vertical",
  stepsType,
}: SequencedAnimationsContextContextProviderProps) {
  /** Scrollable effect only makes sense on larger screen sizes */
  const refContainer = useRef<HTMLDivElement>(null);

  /**
   * Hook that handles updating the current and previous accordion state
   * based on the user's scroll position. Also returns a function that
   * can be used to update the current and previous accordion state.
   */
  const { handleUserSetState, accordionState } = useSectionScrollProgress({
    accordionLength: length,
  });

  useDirectionAwareTransitions({
    current: accordionState.current,
    previous: accordionState.previous,
    refContainer,
    orientation,
    stepsType,
  });

  /** Memoize the context value to prevent unnecessary re-renders. */
  const value: SequencedAnimationsContextContextProvided = useMemo(() => {
    return {
      handleUserSetState,
      current: accordionState.current,
      previous: accordionState.previous,
    };
  }, [accordionState, handleUserSetState]);

  return (
    <SequencedAnimationsContext.Provider value={value}>
      <Box position="relative" ref={refContainer}>
        {children}
      </Box>
    </SequencedAnimationsContext.Provider>
  );
}
