/* eslint-disable react/jsx-no-useless-fragment */
import React from "react";

interface HideChildrenProps {
  when: unknown;
  children: React.ReactNode;
}

export function HideChildren({ when, children }: HideChildrenProps) {
  if (when) {
    return null;
  }

  return <>{children}</>;
}
